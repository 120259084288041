import React, { useEffect } from "react";
import "./Academic.css";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import AcademicBanner from "../../assets/about_banner.jpg";
import CourseBg from "../../assets/shapes/missionvision_bg.png";
import CourseBg1 from "../../assets/shapes/hexagontop3D.png";

function Academics() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navbar />
      {/* Academic Banner */}
      <section className="page-title academic-banner-title text-center">
        <div className="bg-layer">
          <img src={AcademicBanner} alt="AcademicBanner" />
        </div>
        <div className="container">
          <div className="content-box">
            <ul className="bread-crumb clearfix">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Academics</li>
            </ul>
          </div>
        </div>
      </section>
      {/* Courses */}
      <section className="course-section py-4">
        <div className="container">
          <div className="image-shape">
            <div className="shape-1">
              <img src={CourseBg1} alt="BgDots" />
            </div>
          </div>
          <div className="sec-title mb-4">
            <h1>Academic Courses Offered</h1>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
          <div className="course-block-one fadeInUp h-100">
            <div className="academic-course-details">
            <p>
              At <strong>Ashwini Group of Hospitals</strong>, we are dedicated
              to providing exceptional medical education and training. Our
              academic programs are carefully crafted to prepare medical
              professionals with the knowledge, skill, and experience required
              to be successful in their chosen professional areas. We have a
              variety of properly structured academic courses accredited by the{" "}
              <strong>
                National Board of Examinations in Medical Sciences (NBEMS)
              </strong>{" "}
              so that our trainees are provided with the best education under
              the able mentorship of highly qualified faculty members. Our
              educational programs serve various levels of medical education,
              ranging from postgraduate to fellowship training programs. These
              are designed to deliver in-depth hands-on training, exposure to
              research, and utilization of state-of-the-art technology in
              contemporary medicine.
            </p>
            </div>
            
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
              <div className="course-block-one fadeInUp h-100">
                <div className="course-table h-100">
                  <div className="shape">
                    <img src={CourseBg} alt="CourseBackground" />
                  </div>
                  <div className="table-header">
                    <h3>DNB Courses</h3>
                    <h5>3 Years</h5>
                  </div>
                  <div className="table-content">
                    <p>
                      Accredited by the{" "}
                      <strong>National Board of Examinations</strong>, we
                      provide top-notch Post Graduate training since 2021.
                      Boasting 400 beds across three hospitals, well-equipped
                      departments, and a modern library, we ensure a high case
                      load and up-to-date resources.
                    </p>
                  </div>
                  <div className="table-lists">
                    <ul>
                      <li>Respiratory Medicine (Post MBBS & Post Diploma)</li>
                      <li>Emergency Medicine (Post MBBS)</li>
                      <li>Orthopedics (Post MBBS & Post Diploma)</li>
                    </ul>
                  </div>
                  <div className="text-center mt-auto">
                    <a
                      href="/AcademicDetails/DNB"
                      className="theme-btn btn-one"
                    >
                      <span>Know More</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
              <div className="course-block-one fadeInUp h-100">
                <div className="course-table h-100">
                  <div className="shape">
                    <img src={CourseBg} alt="CourseBackground" />
                  </div>
                  <div className="table-header">
                    <h3>DrNB Courses</h3>
                    <h5>3 Years</h5>
                  </div>
                  <div className="table-content">
                    <p>
                      The <strong>DrNB</strong> program is a super-specialty
                      training course designed for postgraduate doctors seeking
                      to specialize in advanced medical fields. Accredited by{" "}
                      <strong>NBEMS, New Delhi</strong>, our DrNB programs have
                      been instrumental in training highly skilled specialists
                      since 2023.
                    </p>
                  </div>
                  <div className="table-lists">
                    <ul>
                      <li>Neurology (Post PG)</li>
                      <li>Neurosurgery (Post PG)</li>
                      <li>
                        Critical Care (Post PG){" "}
                        <span class="badge bg-danger">New</span>
                      </li>
                      <li>Plastic Surgery (Post PG)</li>
                    </ul>
                  </div>
                  <div className="text-center mt-auto">
                    <a
                      href="/AcademicDetails/DrNB"
                      className="theme-btn btn-one"
                    >
                      <span>Know More</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
              <div className="course-block-one fadeInUp h-100">
                <div className="course-table h-100">
                  <div className="shape">
                    <img src={CourseBg} alt="CourseBackground" />
                  </div>
                  <div className="table-header">
                    <h3>Post-Doctor Fellowship Programs</h3>
                    <h5>3 Months</h5>
                  </div>
                  <div className="table-content">
                    <p>
                      Advance your Medical Expertise with our Specialized
                      Fellowship Programs designed to enhance Clinical Skills
                      and provide hands-on experience in key areas of
                      Orthopedics and Sports Medicine. Each program is led by
                      Seasoned Professionals and tailored to offer in-depth
                      knowledge, procedural competence, and patient management
                      skills.
                    </p>
                  </div>
                  <div className="table-lists">
                    <ul>
                      <li>Arthroplasty Fellowship</li>
                      <li>Sports Injury & Arthroscopy Fellowship</li>
                      <li>Complex Trauma</li>
                      <li>Pelvi-Acetabular Fractures Fellowship</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Academic Sections */}
      <section className="mt-5 mt-lg-3">
        <div className="container">
          <div className="row clearfix">
            <div className="col-lg-5 col-md-12 col-sm-12">
              <div className="academic-sidebar">
                <div className="sidebar-widget">
                  <div className="academic-title">
                    <h3>Why Choose Ashwini Group of Hospitals?</h3>
                    <ul className="custom-style">
                      <li>
                        <strong className="text-primary">Accredited by NBEMS</strong> – Ensuring high
                        educational standards.
                      </li>
                      <li>
                        <strong className="text-primary">State-of-the-Art Infrastructure</strong> –
                        Advanced medical facilities and digital libraries.
                      </li>
                      <li>
                        <strong className="text-primary">Expert Faculty</strong> – Experienced medical
                        professionals dedicated to mentoring.
                      </li>
                      <li>
                        <strong className="text-primary">Comprehensive Clinical Exposure</strong> – High
                        case volume across multiple specialties.
                      </li>
                      <li>
                        <strong className="text-primary">Research & Innovation</strong> – Encouragement
                        for academic publications and participation in
                        conferences.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-7 col-md-12 col-sm-12 ">
              <div className="card mb-3 academic-content ">
                <div className="row g-0 ">
                  <div className="card-body academic-block ">
                    <h3>Admission Procedure</h3>
                    <p>
                      Admissions to the <b>DNB</b> and <b>DrNB</b> programme are conducted
                      through <b>NEET</b> and <b>NEET-SS</b> respectively. After qualifying
                      NEET/NEET-SS, candidates must register and participate in
                      the counselling process conducted by the <b>National Board Of
                      Examinations In Medical Sciences (NBEMS)</b> and <b>Medical
                      Counselling Commity (MCC)</b> official website.
                    </p>
                    <p>
                      Seat allotment is based on <b>merit, rank, and specialty</b>
                      availability in accredited institutions. Training follows
                      NBEMS guidelines, ensuring comprehensive clinical exposure
                      and competency-based medical education in the chosen
                      specialty.
                    </p>
                    <p>
                      <span className="text-title text-decoration-underline">
                        Ashwini Group of Hospitals
                      </span>
                    </p>
                    <p className="lh-1">
                      <span className="text-title">Mail</span> :{" "}
                      <a
                        href="mailto:academics@ashwinihospitals.in"
                        className="text-decoration-none"
                      >
                        academics@ashwinihospitals.in
                      </a>
                    </p>
                    <p className="lh-1">
                      <span className="text-title">Phone</span> :{" "}
                      <a href="tel:8093012752" className="text-decoration-none">
                        8093012752
                      </a>
                      ,{" "}
                      <a href="tel:7894460335" className="text-decoration-none">
                        7894460335
                      </a>
                    </p>
                    <p className="lh-1">
                      <span className="text-title">Website</span> :{" "}
                      <a
                        href="https://www.ashwinihospitals.in/"
                        target="_blank"
                        className="text-primary"
                        rel="noopener noreferrer"
                      >
                        www.ashwinihospitals.in
                      </a>
                    </p>
                    <p className="lh-1">
                      <span className="text-title">
                        &#128204;For DNB & DrNB admissions, refer to:{" "}
                        <a
                          href="https://nbe.edu.in"
                          target="_blank"
                          className="text-primary"
                          rel="noopener noreferrer"
                        >
                          NBEMS Official Website
                        </a>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Facilities Available */}
      <section className="facilities py-4">
        <div className="container">
          <div className="image-shape">
            <div className="shape-1">
              <img src={CourseBg1} alt="BgDots" />
            </div>
          </div>

          <div className="sec-title mb-4">
            <h1>Facilities Available</h1>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
              <div className="facilities-block-one fadeInUp h-100">
                <div className="facilities-table h-100">
                  <div className="shape">
                    <img src={CourseBg} alt="CourseBackground" />
                  </div>
                  <div className="table-header">
                    <h4>e-Classroom</h4>
                  </div>
                  <div className="table-content">
                    <p>
                      At Ashwini Hospitals, we understand the importance of
                      continuous learning and education. Our state-of-the-art
                      e-classrooms are equipped with modern technology to
                      facilitate interactive learning experiences. Whether it's
                      for medical professionals seeking to enhance their skills
                      or patients looking to understand their conditions better,
                      our e-classrooms provide a dynamic learning environment.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
              <div className="facilities-block-one fadeInUp h-100">
                <div className="facilities-table h-100">
                  <div className="shape">
                    <img src={CourseBg} alt="CourseBackground" />
                  </div>
                  <div className="table-header">
                    <h4>e-Library</h4>
                  </div>
                  <div className="table-content">
                    <p>
                      Knowledge is key to delivering exceptional healthcare
                      services. Our extensive e-library offers a wide range of
                      resources, including medical journals, research papers,
                      and educational materials. Accessible to both staff and
                      patients, our e-library fosters continuous learning,
                      research, and innovation within the healthcare community.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
              <div className="facilities-block-one wow fadeInUp h-100">
                <div className="facilities-table h-100">
                  <div className="shape">
                    <img src={CourseBg} alt="CourseBackground" />
                  </div>
                  <div className="table-header">
                    <h4>Hostel</h4>
                  </div>
                  <div className="table-content">
                    <p>
                      We understand that receiving medical treatment or
                      attending training programs may require accommodation for
                      patients and their families. Our comfortable and secure
                      hostel facilities ensure a supportive environment for
                      those needing to stay overnight or for an extended period.
                      With amenities designed for convenience and comfort, our
                      hostels aim to alleviate the stress of being away from
                      home during challenging times.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
              <div className="facilities-block-one wow fadeInUp h-100">
                <div className="facilities-table h-100">
                  <div className="shape">
                    <img src={CourseBg} alt="CourseBackground" />
                  </div>
                  <div className="table-header">
                    <h4>Transportation</h4>
                  </div>
                  <div className="table-content">
                    <p>
                      Ensuring seamless access to healthcare is a priority at
                      Ashwini Hospitals. Our transportation services provide
                      convenient and safe options for patients and staff to
                      reach our facilities. From shuttle services for patients
                      undergoing treatment to transportation arrangements for
                      medical professionals attending conferences or workshops,
                      we strive to make every journey to and from our hospitals
                      as smooth as possible.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
              <div className="facilities-block-one wow fadeInUp h-100">
                <div className="facilities-table h-100">
                  <div className="shape">
                    <img src={CourseBg} alt="CourseBackground" />
                  </div>
                  <div className="table-header">
                    <h4>Canteen</h4>
                  </div>
                  <div className="table-content">
                    <p>
                      Good nutrition plays a vital role in the healing process
                      and overall well-being. Our onsite canteen offers a
                      diverse range of nutritious and delicious meals, catering
                      to varying dietary preferences and requirements. Whether
                      it's a quick snack between appointments or a wholesome
                      meal for patients and their families, our canteen ensures
                      that everyone has access to quality food options within
                      our premises.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Academics;
