import React, { useState } from "react";
import "./ServiceDetails.css";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import CriticalCareBanner from "../../assets/about_banner.jpg";
import Img1 from "../../assets/doctors/samir_sahu.jpg";
import Img2 from "../../assets/doctors/soumyaraj_ghosh.png";
import Img4 from "../../assets/doctors/chinmaya_sahu.png";
import G1 from "../../assets/department photos/criticalcare1.png";
import G2 from "../../assets/department photos/criticalcare2.jpg";
import G3 from "../../assets/department photos/criticalcare3.jpg";
import G4 from "../../assets/department photos/criticalcare4.jpg";
import G5 from "../../assets/department photos/criticalcare5.jpg";
import G6 from "../../assets/department photos/criticalcare6.jpg";
import G7 from "../../assets/department photos/criticalcare7.jpg";
import G8 from "../../assets/department photos/criticalcare8.jpg";
import G9 from "../../assets/department photos/criticalcare9.jpg";
import G10 from "../../assets/department photos/criticalcare13.jpg";
import G11 from "../../assets/department photos/criticalcare10.jpg";
import G12 from "../../assets/department photos/criticalcare11.jpg";
import G13 from "../../assets/department photos/criticalcare12.jpg";

function CriticalCare() {
    const [currentImage, setCurrentImage] = useState('');
    const [currentCaption, setCurrentCaption] = useState("");
    const openModal = (image,caption) => {
        setCurrentCaption(caption);
        setCurrentImage(image);
        const modalElement = document.getElementById('exampleModal');
        const modal = new window.bootstrap.Modal(modalElement);
        modal.show();
    };
    const slides = [
        {
            image: Img1,
            name: "Dr. Samir Sahu",
            qualification: "MD(Medicine), FICCM",
            location: "Ashwini Hospital"
        },
        {
            image: Img2,
            name: "Dr. Soumyaraj Ghosh",
            qualification: "MD(Anesthesiology),IDCCM",
            location: "Ashwini Hospital"
        },
        {
            image: Img4,
            name: "Dr. Chinmaya Sahu",
            qualification: "MD(Anesthesiology)",
            location: "Ashwini Hospital"
        }
    ];

    const gallery = [
        { image: G1,caption: "" },
        { image: G2,caption: "" },
        { image: G3,caption: "" },
        { image: G4,caption: "" },
        { image: G5,caption: "" },
        { image: G6,caption: "" },
        { image: G7,caption: "" },
        { image: G8,caption: "" },
        { image: G9,caption: "" },
        { image: G10,caption: "" },
        { image: G11,caption: "" },
        { image: G12,caption: "" },
        { image: G13,caption: "" },
    ]
    return (
        <>
            <Navbar />

            {/* Critical Care Banner */}
            <section className="page-title text-center">
                <div className="bg-layer">
                    <img src={CriticalCareBanner} alt="Critical Care Banner" title="Critical Care Banner"/>
                </div>
                <div className="container">
                    <div className="content-box">
                        <ul className="bread-crumb clearfix">
                            <li><Link to={"/"}>Home</Link></li>
                            <li><Link to={"/Departments"}>Departments</Link></li>
                            <li>Critical Care</li>
                        </ul>
                    </div>
                </div>
            </section>

            {/* Critical Care Doctors */}
            <div className="my-3">
                <div className="container">
                    <div className="sec-title">
                        <h1>Our Esteemed Consultants</h1>
                    </div>

                    <div className="row row-cols-1 row-cols-md-4 g-4 justify-content-center">
                        {slides.map((slide, index) => (
                            <div className="col-lg-3 col-md-4" key={index}>
                                <a className="doc-name"href={`/Doctors/${slide.name.replace(/\s+/g, '').toLowerCase()}`}>
                                <div className="card h-100 doctor_img">
                                    <img src={slide.image} alt={`${slide.name} Img`} title={slide.name} />
                                    <div className="doc-text">
                                        <h3>{slide.name}</h3>
                                        <p>{slide.qualification}</p>
                                        <span className="text-dark">{slide.location}</span>
                                    </div>
                                </div>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Section */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12 mb-3">
                    {/* <div className="card mb-3">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">OPD Timings</h2>
                                <div className="widget-content">
                                    <div className="accordion" id="opdTimingsAccordion">
                                        {opdTimings.map((opd, index) => (
                                            <div className="accordion-item" key={`opd-${index}`}>
                                                <h2 className="accordion-header" id={`heading${index}`}>
                                                    <button
                                                        className={`accordion-button ${openIndex === index ? "" : "collapsed"}`}
                                                        type="button"
                                                        onClick={() => toggleAccordion(index)}
                                                        aria-expanded={openIndex === index ? "true" : "false"}
                                                        aria-controls={`collapse${index}`}
                                                    >
                                                        {opd.doctor}
                                                        <span className="toggle-text">
                                                            {openIndex === index ? "Hide" : "Show"}
                                                        </span>
                                                    </button>
                                                </h2>
                                                <div
                                                    id={`collapse${index}`}
                                                    className={`accordion-collapse collapse ${openIndex === index ? "show" : ""}`}
                                                    aria-labelledby={`heading${index}`}
                                                    data-bs-parent="#opdTimingsAccordion"
                                                >
                                                    <div className="accordion-body">
                                                        <table className="table table-hover table-light">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">DAYS</th>
                                                                    <th scope="col">TIMINGS</th>
                                                                    <th scope="col">CENTRE</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {opd.schedule.map((schedule, idx) => (
                                                                    <tr className="opd-table" key={`schedule-${index}-${idx}`}>
                                                                        <th scope="row">{schedule.day}</th>
                                                                        <td>{schedule.time}</td>
                                                                        <td>{schedule.centre}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* gallery */}
                        <div className="card">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">Media</h2>
                                <div className="widget-content clearfix">
                                    <ul className="image-list">
                                        {gallery.map((g, index) => (
                                            <li key={index}>
                                                <figure><img src={g.image} alt="Critical Care Gallery" title="Critical Care Department" onClick={() => openModal(g.image,g.caption)} /></figure>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* Modal */}
                        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h1 className="modal-title fs-5" id="exampleModalLabel">Image Preview</h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <p>{currentCaption}</p>
                                        <img src={currentImage} alt="Critical Care Gallery" title="Critical Care Department" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-12 col-sm-12 mb-3">
                        <div className="card">
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="card-text">
                                        <p>The Department of Critical Care Medicine is a state of art centre in the silver city of Cuttack. This speciality of Medicine caters to looking after critically ill patients from medical and surgical specialities with life threatening conditions and multiorgan failure requiring comprenhensive and constant monitoring often not possible in the regular wards.</p>

                                        <p>We run a 25 bedded ICU with sophisticated equipment in the 2nd floor of the Block building. We provide round the clock evidence based and affordable care to our patients with our dedicated team of qualified intensivists, critical care nurses, physiotherapists and dieticians. It is also a centre for IDCCM/ CTCCM/ ISCCN programme for the training of doctors and nurses who are interested to make their careers in critical care medicine.</p>

                                        <span className="text-title">Work at ICU</span>
                                        <p>The patients are given care by qualified intensivists, resident doctors and nursing staff .The department has its own evidence based protocol to provide quality care at all times to critically ill patients. The patient care is provided by dedicated team of doctors and nurses in shifts and written handover of information between physician to physician and nursing staff to nursing staff to avoid any miscommunication. The department has monthly scheduled departmental meetings, mortality and morbidity meetings. The patient data are captured at frequent intervals for ensuring patient safety improving patient care.Scheduled routine medical audits are done in various aspects of patient care and protocols are revised and newer policies would be implemented.</p>
                                    </div>
                                </div>
                            </div>

                            {/* Clinical Services */}
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="sec-title">
                                        <h1>Clinical Services</h1>
                                    </div>
                                    <div className="card-text">
                                        <ul className="ul_list">
                                            <li>Bedside bronchoscopy</li>
                                            <li>24 hour presence of residents at the bedside and faculty cover</li>
                                            <li>Bedside echocardiography</li>
                                            <li>Bedside Ultrasonography</li>
                                            <li>Continuous renal replacement therapy at the bedside</li>
                                            <li>Bedside percutaneous dilatational tracheostomy</li>
                                            <li>Assessment, advice and transfer of sick patients to the ICU</li>
                                            <li>Airway assessment and management</li>
                                            <li>Placement of central venous lines</li>
                                            <li>Advice regarding parenteral nutrition of patients</li>
                                            <li>PACS- (picture archiving and communication system) installed in all the systems
                                                for storage and access to images from multiple modalities</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CriticalCare;