import React, { useState } from "react";
import "./Events.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Navbar from "../../Navbar/Navbar";
import Shape1 from "../../../assets/shapes/plus.png";
import Img1 from "../../../assets/gallery/g119.jpg";
import Img2 from "../../../assets/gallery/g120.jpg";
import Img3 from "../../../assets/gallery/g53.jpg";
import Img4 from "../../../assets/gallery/g54.jpg";
import Img5 from "../../../assets/gallery/g55.jpg";
import Img6 from "../../../assets/gallery/g56.jpg";
import Img7 from "../../../assets/gallery/g57.jpg";
import Img8 from "../../../assets/gallery/g58.jpg";
import Img9 from "../../../assets/gallery/g60.jpg";
import Img10 from "../../../assets/gallery/g61.jpg";
import Img11 from "../../../assets/gallery/g62.jpg";
import Img12 from "../../../assets/gallery/g64.jpg";
import Img13 from "../../../assets/gallery/g65.jpg";

export default function Event2() {
  const [currentImage, setCurrentImage] = useState("");
  const openModal = (image) => {
    setCurrentImage(image);
    const modalElement = document.getElementById("exampleModal");
    const modal = new window.bootstrap.Modal(modalElement);
    modal.show();
  };

  const EventImg = [
    Img4,
    Img5,
    Img6,
    Img7,
    Img8,
    Img9,
    Img10,
    Img11,
    Img12,
    Img13,
  ];

  return (
    <>
      <Navbar />
      <div className="container event-page ">
        <div className="row">
          <div className="col-md-12">
            <div className="card mb-4">
              <div className="card-body">
                <h1 className="event-title">
                  Ashwini Group: 19 Years of Excellence in Healthcare and
                  Medical Education
                </h1>
              </div>
              <p className="intro">
                A Legacy of Healing, Hope, and Education. On this special
                occasion, we celebrate the{" "}
                <b>19th Foundation Day of Ashwini Group</b>, marking nearly two
                decades of unwavering dedication to patient care, medical
                excellence, and innovation. Since its inception, Ashwini Group
                has been a beacon of hope for countless patients, setting new
                benchmarks in <b>Healthcare and Medical Education</b> across
                Odisha. With the establishment of its medical college, Ashwini
                Group has expanded its commitment to nurturing the next
                generation of healthcare professionals, ensuring excellence in
                both patient care and academic training.The journey of Ashwini
                Group would not have been possible without the vision and
                leadership of its
                <b> Founder</b>,{" "}
                <a
                  href="Doctors/dr.subratkumarjena"
                  className="text-decoration-none"
                >
                  Dr. Subart Kumar Jena
                </a>
                ,<b> Co-Founder</b>,
                <a
                  href="Doctors/dr.mayagantayet"
                  className="text-decoration-none"
                >
                  {" "}
                  Dr. Maya Gantayet
                </a>
                . Their relentless pursuit of excellence and dedication to
                patient welfare have transformed Ashwini into a premier
                healthcare institution, providing top-notch medical services
                with compassion and innovation.
              </p>
              <div className="sec-one">
                <LazyLoadImage
                  src={Img1}
                  alt="19th-Foundation-Day"
                  title="19th-Foundation-Day"
                  effect="blur"
                />
                <div className="sec-one-details">
                  <p className="sub-intro">The Inauguration Ceremony</p>
                  <div className="sec-one-shape">
                    <LazyLoadImage src={Shape1} alt="shape" effect="blur" />
                    <p>
                      The Foundation Day celebrations commenced with a grand
                      inauguration ceremony, graced by eminent doctors, hospital
                      management, and esteemed guests.
                    </p>
                  </div>
                  <div className="sec-one-shape">
                    <LazyLoadImage src={Shape1} alt="shape" effect="blur" />
                    <p>
                      The event was marked by a lamp-lighting, done by the
                      Founder and Co-Founder along side the Director Of Finance,
                      Mrs. Suma Devi Dash.The ceremony symbolizing the guiding
                      light of healing that Ashwini Group has provided over the
                      years.
                    </p>
                  </div>
                  <div className="sec-one-shape">
                    <LazyLoadImage src={Shape1} alt="shape" effect="blur" />
                    <p>
                      The hospital’s leadership reminisced about the incredible
                      journey, emphasizing milestones such as advanced medical
                      technologies, robotic surgeries, and life-saving
                      procedures that have transformed patient care.
                    </p>
                  </div>
                </div>
              </div>
              <div className="sec-two">
                <LazyLoadImage
                  src={Img2}
                  alt="19th-Foundation-Day"
                  title="19th-Foundation-Day"
                  effect="blur"
                />
                <div className="sec-two-details">
                  <p className="sub-intro">
                    A Symphony of Joy: Music and Dance Performances
                  </p>
                  <div className="sec-one-shape">
                    <LazyLoadImage src={Shape1} alt="shape" effect="blur" />
                    <p>
                      Felicitation of doctors, nurses, and staff members who
                      have contributed tirelessly to the hospital’s success.
                    </p>
                  </div>
                  <div className="sec-one-shape">
                    <LazyLoadImage src={Shape1} alt="shape" effect="blur" />
                    <p>
                      A vibrant display of dance, music performances showcased
                      the artistic talents of staff,students and healthcare
                      workers, adding joy and celebration to the occasion.
                    </p>
                  </div>
                  <div className="sec-one-shape">
                    <LazyLoadImage src={Shape1} alt="shape" effect="blur" />
                    <p>
                      Engaging performances and speeches from senior doctors.
                    </p>
                  </div>
                </div>
              </div>
              <div className="sec-three d-flex">
                <LazyLoadImage
                  src={Img3}
                  alt="19th-Foundation-Day"
                  title="19th-Foundation-Day"
                  effect="blur"
                />
                <div className="sec-three-details">
                  <p className="sub-intro">Towards a Healthier Tomorrow</p>
                  <div className="sec-one-shape">
                    <LazyLoadImage src={Shape1} alt="shape" effect="blur" />
                    <p>
                      As we celebrate this milestone, we reaffirm our pledge to
                      deliver world-class healthcare with compassion and
                      innovation.
                    </p>
                  </div>
                  <div className="sec-one-shape">
                    <LazyLoadImage src={Shape1} alt="shape" effect="blur" />
                    <p>
                      With state-of-the-art medical facilities, a dedicated team
                      of experts, and an unyielding passion for excellence,
                      Ashwini Group will continue its mission to serve and save
                      lives. <b>“Your Health, Our Mission – Together Towards a
                      Healthier Future!”</b>
                    </p>
                  </div>
                </div>
              </div>
              <div className="event-gallery">
                <div className="text-center">
                  <h2>Event Gallery</h2>
                </div>
                <div className="event-gallery-grid">
                  {EventImg.map((image, index) => (
                    <LazyLoadImage
                      key={index}
                      src={image}
                      alt={`Event Gallery ${index + 1}`}
                      onClick={() => openModal(image)}
                      effect="blur"
                      style={{ cursor: "pointer" }}
                    />
                  ))}
                </div>
              </div>
              <div
                className="modal fade"
                id="exampleModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h1 className="modal-title fs-5" id="exampleModalLabel">
                        Image Preview
                      </h1>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <LazyLoadImage
                        src={currentImage}
                        alt="19th-Foundation-Day"
                        title="19th-Foundation-Day"
                        effect="blur"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
