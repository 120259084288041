import React, { useState, useEffect } from "react";
import './Academic.css';
import Navbar from "../Navbar/Navbar";

function DrNB() {
    const [activeTab, setActiveTab] = useState('Courses');

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Navbar />

            {/* DrNB Message */}
            <div className="card course-title">
                <div className="container card-body">
                    <div className="sec-title">
                        <h1>DrNB Course Details</h1>
                    </div>
                    <div className="text-box">
                        <p>Accredited by the National Board of Examinations in Medical Sciences, New Delhi, we proudly offer Post Graduate training to DrNB Trainees in various fields since 2023. Our well-equipped departments span three hospitals with a total bed strength of 400. Explore our state-of-the-art library housing the latest reference books and journals. Benefit from full-time internet facilities and tablets for seamless learning. Hostel facilities are available as per availability. Stipends are in accordance with State Government Guidelines. Join ASHWINI for an enriching and impactful medical education experience.</p>
                    </div>
                </div>
            </div>

            {/* DrNB structure */}
            <div className="course-box mb-3">
                <div className="container">
                    <ul className="nav nav-tabs">
                        <li className="nav-item tab_option">
                            <button className={`nav-link ${activeTab === 'Courses' ? 'active' : ''}`} onClick={() => handleTabClick('Courses')}>Courses</button>
                        </li>
                        <li className="nav-item tab_option">
                            <button className={`nav-link ${activeTab === 'Fee Structure' ? 'active' : ''}`}
                                onClick={() => handleTabClick('Fee Structure')}>Fee Structure</button>
                        </li>
                        <li className="nav-item tab_option">
                            <button className={`nav-link ${activeTab === 'Stipend' ? 'active' : ''}`}
                                onClick={() => handleTabClick('Stipend')}>Stipend</button>
                        </li>
                        <li className="nav-item tab_option">
                            <button className={`nav-link ${activeTab === 'Required Documents' ? 'active' : ''}`}
                                onClick={() => handleTabClick('Required Documents')}>Required Documents</button>
                        </li>
                    </ul>

                    <div className="tab-content">
                        {activeTab === 'Courses' && (
                            <div>
                                <table className="table table-bordered">
                                    <thead className="table-light">
                                        <tr>
                                            <th scope="col">Site</th>
                                            <th scope="col">Speciality</th>
                                            <th scope="col">Course</th>
                                            <th scope="col">No. of seats</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Ashwini Hospital</td>
                                            <td>Neurology</td>
                                            <td>Post PG</td>
                                            <td>3 seats</td>
                                        </tr>
                                        <tr>
                                            <td>Ashwini Hospital</td>
                                            <td>Neurosurgery</td>
                                            <td>Post PG</td>
                                            <td>2 seats</td>
                                        </tr>
                                        <tr>
                                            <td>Ashwini Hospital</td>
                                            <td>Critical Care <span class="badge bg-danger">New</span></td>
                                            <td>Post PG</td>
                                            <td>2 seats</td>
                                        </tr>
                                        <tr>
                                            <td>Ashwini Trauma Centre</td>
                                            <td>Plastic Surgery</td>
                                            <td>Post PG</td>
                                            <td>2 seats</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )}

                        {activeTab === 'Fee Structure' && (
                            <div className="fees">
                                <ul>
                                    <li><span>1st year</span> : Rs 1,25,000/- (Per Year)</li>
                                    <li><span>2nd year</span> : Rs 1,25,000/- (Per Year)</li>
                                    <li><span>3rd year</span> : Rs 1,25,000/- (Per Year)</li>
                                </ul>
                            </div>
                        )}

                        {activeTab === 'Stipend' && (
                            <div className="stipend">
                                <ul>
                                    <li><span>1st year</span> : Rs. 85,000/-</li>
                                    <li><span>2nd year</span> : Rs. 90,000/-.</li>
                                    <li><span>3rd year</span> : Rs. 95,000/-</li>
                                </ul>
                            </div>
                        )}

                        {activeTab === 'Required Documents' && (
                            <div className="documents">
                                <ul>
                                    <li>DNB/DrNB Joining Report</li>
                                    <li>DNB/DrNB fee submission 1st year receipt</li>
                                    <li>MCC Provisional Allotment Letter</li>
                                    <li>NEET Score Card</li>
                                    <li>Provisional admission letter</li>
                                    <li>NEET Admit Card</li>
                                    <li>Candidate medical registration latest certificate</li>
                                    <li>Internship Certificate</li>
                                    <li>MBBS Provisional Certificate</li>
                                    <li>MBBS All Marksheets</li>
                                    <li>10th Pass Certificate</li>
                                    <li>10th Marksheets</li>
                                    <li>Post MBBS Certificate Marksheets</li>
                                    <li>Aadhar Card</li>
                                    <li>PAN Card</li>
                                    <li>Cancelled Cheque</li>
                                    <li>Relieved Order(if any)</li>
                                    <li>Transfer Certificate or College Leaving Certificate</li>
                                    <li>Character Certificate</li>
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default DrNB;