import React, { useState } from "react";
import "./ServiceDetails.css";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import DentalBanner from "../../assets/about_banner.jpg";
import G1 from "../../assets/department photos/dental1.jpg";

function Dental() {
    const [openIndex, setOpenIndex] = useState(null);
    const [currentImage, setCurrentImage] = useState('');
    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index); // Toggle accordion open/close
    };
    const openModal = (image) => {
        setCurrentImage(image);
        const modalElement = document.getElementById('exampleModal');
        const modal = new window.bootstrap.Modal(modalElement);
        modal.show();
    };
    const slides = [
        
    ];
    const gallery = [
        { image: G1 },
    ];
    const opdTimings = [
        // {
        //     doctor: "Dr. Deepsikha Agarwal",
        //     schedule: [
        //         { day: 'MON', time: '11:00 AM - 05:00 PM', centre: 'Ashwini Wellness' },
        //         { day: 'TUE', time: '11:00 AM - 05:00 PM', centre: 'Ashwini Wellness' },
        //         { day: 'WED', time: '11:00 AM - 05:00 PM', centre: 'Ashwini Wellness' },
        //         { day: 'THU', time: '11:00 AM - 05:00 PM', centre: 'Ashwini Wellness' },
        //         { day: 'FRI', time: '11:00 AM - 05:00 PM', centre: 'Ashwini Wellness' },
        //         { day: 'SAT', time: '11:00 AM - 05:00 PM', centre: 'Ashwini Wellness' },
        //         { day: 'SUN', time: '11:00 AM - 05:00 PM', centre: 'Ashwini Wellness' }
        //     ]
        // }
    ]

    return (
        <>
            <Navbar />

            {/* Dental Banner */}
            <section className="page-title text-center">
                <div className="bg-layer">
                    <img src={DentalBanner} alt="Dental Banner" title="Dental Banner" />
                </div>
                <div className="container">
                    <div className="content-box">
                        <ul className="bread-crumb clearfix">
                            <li><Link to={"/"}>Home</Link></li>
                            <li><Link to={"/Departments"}>Departments</Link></li>
                            <li>Dental</li>
                        </ul>
                    </div>
                </div>
            </section>

            {/* Dental Doctors */}
            <div className="my-3">
                <div className="container">
                    <div className="sec-title">
                        <h1>Our Esteemed Consultants</h1>
                    </div>

                    <div className="row row-cols-1 row-cols-md-4 g-4 justify-content-center">
                        {slides.map((slide, index) => (
                            <div className="col-lg-3 col-md-4" key={index}>
                                <a className="doc-name"href={`/Doctors/${slide.name.replace(/\s+/g, '').toLowerCase()}`}>
                                <div className="card h-100 doctor_img">
                                    <img src={slide.image} alt={`${slide.name} Img`} title={slide.name} />
                                    <div className="doc-text">
                                        <h3>{slide.name}</h3>
                                        <p>{slide.qualification}</p>
                                        <span className="text-dark">{slide.location}</span>
                                    </div>
                                </div>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Section */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12 mb-3">
                        {/* opd timings */}
                        <div className="card mb-3">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">OPD Timings</h2>
                                <div className="widget-content">
                                    <div className="accordion" id="opdTimingsAccordion">
                                        {opdTimings.map((opd, index) => (
                                            <div className="accordion-item" key={`opd-${index}`}>
                                                <h2 className="accordion-header" id={`heading${index}`}>
                                                    <button
                                                        className={`accordion-button ${openIndex === index ? "" : "collapsed"}`}
                                                        type="button"
                                                        onClick={() => toggleAccordion(index)}
                                                        aria-expanded={openIndex === index ? "true" : "false"}
                                                        aria-controls={`collapse${index}`}
                                                    >
                                                        {opd.doctor}
                                                        <span className="toggle-text">
                                                            {openIndex === index ? "Hide" : "Show"}
                                                        </span>
                                                    </button>
                                                </h2>
                                                <div
                                                    id={`collapse${index}`}
                                                    className={`accordion-collapse collapse ${openIndex === index ? "show" : ""}`}
                                                    aria-labelledby={`heading${index}`}
                                                    data-bs-parent="#opdTimingsAccordion"
                                                >
                                                    <div className="accordion-body">
                                                        <table className="table table-hover table-light">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">DAYS</th>
                                                                    <th scope="col">TIMINGS</th>
                                                                    <th scope="col">CENTRE</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {opd.schedule.map((schedule, idx) => (
                                                                    <tr className="opd-table" key={`schedule-${index}-${idx}`}>
                                                                        <th scope="row">{schedule.day}</th>
                                                                        <td>{schedule.time}</td>
                                                                        <td>{schedule.centre}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* gallery photos */}
                        <div className="card">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">Media</h2>
                                <div className="widget-content clearfix">
                                    <ul className="image-list">
                                    {gallery.map((g, index) => (
                                            <li key={index}>
                                                <figure><img src={g.image} alt="Orthopedic Gallery" title="Orthopedic Department" onClick={() => openModal(g.image)} /></figure>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Modal */}
                    <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h1 className="modal-title fs-5" id="exampleModalLabel">Image Preview</h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <img src={currentImage} alt="Orthopedic Gallery" title="Orthopedic Department" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                        </div>

                    <div className="col-lg-8 col-md-12 col-sm-12 mb-3">
                        <div className="card">
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="card-text">
                                        <p>Welcome to the Dental Department at Ashwini Group of Hospitals, where we are committed to providing exceptional dental care with compassion and expertise. Our state-of-the-art facilities and experienced team of dental professionals ensure that every patient receives personalized treatment in a comfortable and welcoming environment.</p>
                                    </div>

                                    {/* Facilities Available */}
                                    <div className="card about-style-two py-0">
                                        <div className="container card-body">
                                            <div className="sec-title">
                                                <h1>Facilities Available</h1>
                                            </div>
                                            <div className="card-text">
                                                <p><span className="text-title">Modern Dental Equipment : </span>Our department is equipped with the latest dental technology, including digital X-rays, intraoral cameras, and dental lasers, allowing for precise diagnosis and treatment.</p>

                                                <p><span className="text-title">Comprehensive Services : </span>From routine cleanings and preventive care to restorative procedures and cosmetic dentistry, we offer a wide range of dental services to meet your oral health needs.</p>

                                                <p><span className="text-title">Sedation Dentistry : </span>For patients who experience dental anxiety or fear, we offer sedation options to help them relax and feel comfortable during their dental appointments.</p>

                                                <p><span className="text-title">Pediatric Dentistry :</span>We understand the unique needs of young patients and provide gentle and compassionate dental care for children of all ages.</p>

                                                <p><span className="text-title">Emergency Dental Care : </span>Our dental team is available to address dental emergencies promptly, providing relief and treatment when you need it most.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Dental;