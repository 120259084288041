import img1 from '../../assets/gallery/g81.jpg'
import img2 from '../../assets/gallery/g85.jpg'
import img3 from '../../assets/pg medal.jpg'
import img4 from '../../assets/gallery/g105.jpg'


const news = [
    {
        title: 'Maternal Health Awareness Day',
        description: 'We unite in celebrating the strength and resilience of mothers while emphasizing the importance of their health and well-being.',
        image: img4,
        link: '/Events/Maternal-Health-Awareness-Day',
      },
    {
        title: 'Honoring Resident Excellence',
        description: 'Kudos to Dr. Anshuman Agrawal, Resident of Ashwini Group of Hospitals, for winning the prestigious Dr. U.C. Jena Best PG Medal Paper award at OOACON!',
        image: img3,
        link: 'https://www.linkedin.com/feed/update/urn:li:activity:7284889957515567104',
      },
    {
        title: 'Stroke Day Walkathon',
        description: 'Ashwini Group of Hospitals marked World Stroke Day with a Walkathon and Yoga Session in Bhubaneswar.',
        image: img1,
        link: '/Events/Walkathon-Yoga-Session-for-Stroke-Awareness',
      },
      {
        title: 'About Ashwini Hospitals',
        description: 'Ashwini Hospital & Trauma Centre is Odisha s leading superspecialty hospital. This footage from Dec 2023, was featured in NDTV National channel and gives a walkthrough of the facilities & infrastructure at Ashwini.',
        video: "https://www.youtube.com/embed/o0AL270Y4qk?si=YqDNQBeUyepC6bhy", 
        link: 'https://www.youtube.com/watch?v=o0AL270Y4qk',
      },
      {
        title: 'What To Do If Stroke Happens?',
        description: 'In this essential guide, Dr. Maya Gantayet, a prominent Neurologist at Ashwini Group of Hospitals, explains the critical steps to take if a stroke occurs.',
        video: "https://www.youtube.com/embed/j3kCYZfIoMY?si=iAh2RFaUc6W8ymU_",
        link: 'https://www.youtube.com/watch?v=j3kCYZfIoMY',
      },
      {
        title: 'Cryotherapy Workshop',
        description: 'The Department of Pulmonary Medicine at Ashwini Group of Hospitals recently hosted a transformative Hands-On Training Workshop on Cryotherapy in Lung Diseases, marking a significant leap forward in pulmonary care and innovation.',
        image: img2,
        link: '/Events/Cryotherapy-Workshop-at-Ashwini-Group-of-Hospitals',
      },
      {
        title: 'Negative Pressure Wound Therapy',
        description: 'Negative Pressure Wound Therapy (NPWT) has become a pivotal technique in managing complex wounds, particularly in trauma cases, general surgical wounds, and diabetic foot ulcers.',
        link: '/Blog/NPWT', 
      },
      {
        title: 'Robotic Knee Replacement',
        description: ' Ashwini Hospital is proud to introduce Cuttack’s first robotic surgery, marking a new era in precision and minimally invasive procedures.',
        video: "https://www.youtube.com/embed/H0e9YV-Ev2A?si=HEpOj7_vb0SECTFZ", 
        link: '/Blog/Robotic',
      }
    ];
  export default news;