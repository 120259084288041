import React, { useState } from "react";
import "./Events.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Navbar from "../../Navbar/Navbar";
import Shape1 from "../../../assets/shapes/plus.png";
import Img1 from "../../../assets/gallery/g106.jpg";
import Img2 from "../../../assets/gallery/g108.jpg";
import Img3 from "../../../assets/gallery/g107.jpg";
import Img4 from "../../../assets/gallery/g109.jpg";
import Img5 from "../../../assets/gallery/g110.jpg";
import Img6 from "../../../assets/gallery/g111.jpg";
import Img7 from "../../../assets/gallery/g112.jpg";
import Img8 from "../../../assets/gallery/g113.jpg";
import Img9 from "../../../assets/gallery/g114.jpg";
import Img10 from "../../../assets/gallery/g115.jpg";
import Img11 from "../../../assets/gallery/g116.jpg";
import Img12 from "../../../assets/gallery/g117.jpg";
import Img13 from "../../../assets/gallery/g118.jpg";


export default function Event9() {
  const [currentImage, setCurrentImage] = useState("");
  const openModal = (image) => {
    setCurrentImage(image);
    const modalElement = document.getElementById("exampleModal");
    const modal = new window.bootstrap.Modal(modalElement);
    modal.show();
  };

  const EventImg = [
    Img4,
    Img5,
    Img6,
    Img7,
    Img8,
    Img9,
    Img10,
    Img11,
    Img12,
    Img13,
  ];

  return (
    <>
      <Navbar />
      <div className="container event-page ">
        <div className="row">
          <div className="col-md-12">
            <div className="card mb-4">
              <div className="card-body">
                <h1 className="event-title">
                Maternal Health Awareness Day: Prioritizing Every Mother and Child
                </h1>
              </div>
              <p className="intro">
              Today, on Maternal Health Awareness Day, we unite in celebrating the strength and resilience of mothers while emphasizing the importance of their health and well-being.
              </p>
              <div className="sec-one">
                <LazyLoadImage
                  src={Img1}
                  alt="Maternal Health Awareness Day"
                  title="Maternal Health Awareness Day"
                  effect="blur"
                />
                <div className="sec-one-details">
                  <p className="sub-intro">{}</p>
                  <div className="sec-one-shape">
                    <LazyLoadImage src={Shape1} alt="shape" effect="blur" />
                    <p>
                    Maternal health is the cornerstone of a thriving society, ensuring that every mother and child has access to the care they deserve.
                    </p>
                  </div>
                  <div className="sec-one-shape">
                    <LazyLoadImage src={Shape1} alt="shape" effect="blur" />
                    <p>
                    At Aditya Ashwini, we are dedicated to providing comprehensive maternal and child healthcare.
                    </p>
                  </div>
                </div>
              </div>
              <div className="sec-two">
                <LazyLoadImage
                  src={Img2}
                  alt="Maternal Health Awareness Day"
                  title="Maternal Health Awareness Day"
                  effect="blur"
                />
                <div className="sec-two-details">
                  <p className="sub-intro">{}</p>
                  <div className="sec-one-shape">
                    <LazyLoadImage src={Shape1} alt="shape" effect="blur" />
                    <p>
                    From prenatal care to postnatal support, we stand by your side at every step of this incredible journey.
                    </p>
                  </div>
                  <div className="sec-one-shape">
                    <LazyLoadImage src={Shape1} alt="shape" effect="blur" />
                    <p>
                    Our expert team, state-of-the-art facilities, and patient-centered approach ensure that both mothers and newborns receive the best possible care.
                    </p>
                  </div>
                  
                </div>
              </div>
              <div className="sec-three d-flex">
                <LazyLoadImage
                  src={Img3}
                  alt="Maternal Health Awareness Day"
                  title="Maternal Health Awareness Day"
                  effect="blur"
                />
                <div className="sec-three-details">
                  <p className="sub-intro">{}</p>
                  <div className="sec-one-shape">
                    <LazyLoadImage src={Shape1} alt="shape" effect="blur" />
                    <p>
                    Let's take this day to raise awareness, inspire action, and reaffirm our commitment to the health and happiness of every mother and child.
                    </p>
                  </div>
                </div>
              </div>
              <div className="event-gallery">
                <div className="text-center">
                  <h2>Event Gallery</h2>
                </div>
                <div className="event-gallery-grid">
                  {EventImg.map((image, index) => (
                    <LazyLoadImage
                      key={index}
                      src={image}
                      alt={`Event Gallery ${index + 1}`}
                      onClick={() => openModal(image)}
                      effect="blur"
                      style={{ cursor: "pointer" }}
                    />
                  ))}
                </div>
              </div>
              <div
                className="modal fade"
                id="exampleModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h1 className="modal-title fs-5" id="exampleModalLabel">
                        Image Preview
                      </h1>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <LazyLoadImage
                        src={currentImage}
                        alt="Maternal Health Awareness Day"
                        title="Maternal Health Awareness Day"
                        effect="blur"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
