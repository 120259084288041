import React, { useState } from "react";
import Navbar from "../../Navbar/Navbar";
import "./Blog.css";
import img1 from "../../../assets/Blog Assets/Blog2.png";
import img2 from "../../../assets/Blog Assets/bg2-1.jpg";
import img3 from "../../../assets/Blog Assets/bg2-2.jpg";
import img4 from "../../../assets/Blog Assets/bg2-3.jpg";
import img5 from "../../../assets/Blog Assets/bg2-4.jpg";
import img6 from "../../../assets/Blog Assets/bg2-5.jpg";

export default function Blog2() {
  const [selectedImage, setSelectedImage] = useState("");

  const images = [img3, img4, img5, img6];
  return (
    <>
      <Navbar />
      <div className="container blog-page ">
        <div className="row">
          <div className="col-md-12">
            <div className="card mb-4">
              <div className="card-body">
                <h1 className="blog-title">
                  Cuttack’s First Robotic Knee Replacement Surgery Now at
                  Ashwini Hospital !!!
                </h1>
                <img
                  src={img1}
                  alt="Robotic Knee Replacement Surgery"
                  className="img-fluid blog-image mb-4"
                />
                <p className="blog-font-size">
                  Medical advancements continue to redefine healthcare, bringing
                  innovative solutions for better patient outcomes.{" "}
                  <span>
                    <a
                      href="https://www.ashwinihospitals.in"
                      className="text-decoration-none"
                    >
                      Ashwini Hospital
                    </a>
                  </span>{" "}
                  is proud to introduce Cuttack’s first robotic surgery, marking
                  a new era in precision and minimally invasive procedures. This
                  groundbreaking technology is now available for Knee
                  Replacement Surgery, offering patients a life-changing
                  experience with enhanced mobility and a faster recovery.
                </p>

                <h2 className="blog-sub-heading">What is Robotic Surgery?</h2>
                <p>
                  Robotic surgery is a state-of-the-art medical technique where
                  surgeons use robotic-assisted technology to perform complex
                  procedures with enhanced accuracy. The system offers better
                  visualization, greater control, and minimal invasion, leading
                  to improved results.
                </p>
                <img
                  src={img2}
                  alt="ot"
                  className="img-fluid blog-image mb-4"
                />
                <p>
                  It allows surgeons to perform highly intricate tasks that
                  require precision, stability, and flexibility, ensuring a more
                  successful and efficient outcome.This technology minimizes
                  tissue damage, reduces post-operative pain, and accelerates
                  recovery, making it a revolutionary advancement in modern
                  healthcare.
                </p>

                <h2 className="blog-sub-heading">
                  Advantages of Robotic Surgery
                </h2>
                <p>
                  Robotic-assisted procedures offer several benefits compared to
                  traditional surgery:
                </p>
                <ul className="list-item-ul">
                  <li>
                    <strong>Higher Precision:</strong> The robotic system allows
                    precise movements, reducing human errors and ensuring that
                    every step of the surgery is meticulously planned and
                    executed.
                  </li>
                  <li>
                    <strong>Minimally Invasive:</strong> Smaller incisions lead
                    to less pain, quicker recovery, and reduced scarring.
                  </li>
                  <li>
                    <strong>Reduced Complications:</strong> Enhanced control
                    during surgery helps minimize the risk of complications and
                    ensures better outcomes.
                  </li>
                  <li>
                    <strong>Faster Recovery:</strong> Patients benefit from
                    shorter hospital stays and quicker healing, allowing them to
                    return to daily activities sooner.
                  </li>
                  <li>
                    <strong>Less Blood Loss:</strong> The minimally invasive
                    nature of robotic surgery results in less blood loss and
                    reduced need for transfusions.
                  </li>
                </ul>

                <h2 className="blog-sub-heading">
                  Why Choose Ashwini Hospital for Robotic Surgery?
                </h2>
                <p className="blog-font-size">
                  {" "}
                  <span>
                    <a
                      href="https://www.ashwinihospitals.in"
                      className="text-decoration-none"
                    >
                      Ashwini Hospital
                    </a>
                  </span>{" "}
                  has always been at the forefront of medical innovation,
                  bringing the latest advancements to our patients. With our
                  expert surgeons and cutting-edge robotic technology, we
                  provide the highest standard of care.{" "}
                  <span>
                    <a href="/Orthopedics" className="text-decoration-none">
                      Our Team
                    </a>
                  </span>{" "}
                  is dedicated to achieving the best possible surgical outcomes,
                  ensuring that each patient receives personalized and expert
                  care.We believe in providing advanced solutions that cater to
                  individual needs, offering not only precision but also comfort
                  throughout the surgical journey.
                </p>
                <div className="container my-5">
                  <h3 className="text-center mb-4">Robotic Surgery Media</h3>
                  <div className="row g-4">
                    {images.map((img, index) => (
                      <div className="col-md-3 col-sm-6" key={index}>
                        <div className="card shadow-sm media-card">
                          <img
                            src={img}
                            alt={`Robotic Surgery ${index + 1}`}
                            className="img-fluid "
                            data-bs-toggle="modal"
                            data-bs-target="#imageModal"
                            onClick={() => setSelectedImage(img)}
                          />
                        </div>
                      </div>
                    ))}
                    <div className="col-md-3 col-sm-6">
                      <div className="card shadow-sm media-card">
                        <div className="card">
                          <iframe
                            src="https://youtube.com/embed/Jmspko4ro5o?si=5QymIVStXTTzdyqN"
                            title="Robotic Surgery Video"
                            allowFullScreen
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h2 className="blog-sub-heading text-danger">Book a Consultation</h2>
                  <p>
                    If you or your loved ones are considering surgery, explore
                    the incredible benefits of robotic-assisted procedures at
                    Ashwini Hospital. Embrace the future of surgery and
                    experience the difference!
                  </p>
                  <p>
                    For appointments and inquiries, contact us at{" "}
                    <a href="tel:7608005555"className="text-decoration-none"><span className="text-danger fs-3 fw-bold">7608005555.</span></a>
                  </p>
                  {/* Modal for Image View */}
                  <div
                    className="modal fade"
                    id="imageModal"
                    tabIndex="-1"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-body">
                          <img
                            src={selectedImage}
                            alt="Enlarged View"
                            className="img-fluid"
                          />
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
