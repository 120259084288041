import React from "react";
import {useEffect,useState} from "react";
import Confetti from "react-confetti";
import Navbar from "../Navbar/Navbar";
import Banner from "./Banner";
import Location from "./Location";
import Message from "./Message";
import Booking from "./Booking";
import Partners from "./Partners";
// import DepartmentsSection from "./DepartmentsSection";
import HealthPackages from "./HealthPackages";
import Stats from "./Stats";
import LatestNews from "./LatestNews";
import Img from "../../assets/department photos/robotic surgery.jpeg"

function Homepage() {
    //New Modal Start//
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const modalShown = sessionStorage.getItem('modalShown');
        if (!modalShown) {
            const timer = setTimeout(() => {
                setShowModal(true);
                sessionStorage.setItem('modalShown', 'true');
            }, 1500);
            return () => clearTimeout(timer);
        }
    }, []);

    const handleClose = () => setShowModal(false);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    //New Modal End//

    // useEffect(() => {
    //     const modalElement = document.getElementById('exampleModal');
    //     const modal = new window.bootstrap.Modal(modalElement);
    //     modal.show();

    //     // Handle cleanup
    //     const handleModalHidden = () => {
    //         document.body.classList.remove('modal-open');
    //         const backdrop = document.querySelector('.modal-backdrop');
    //         if (backdrop) {
    //             backdrop.remove();
    //         }
    //     };

    //     const handleBackdropClick = (event) => {
    //         if (event.target === modalElement) {
    //             modal.hide();
    //         }
    //     };

    //     modalElement.addEventListener('hidden.bs.modal', handleModalHidden);
    //     modalElement.addEventListener('click', handleBackdropClick);

    //     // Cleanup event listener on unmount
    //     return () => {
    //         modalElement.removeEventListener('hidden.bs.modal', handleModalHidden);
    //         modalElement.removeEventListener('click', handleBackdropClick);
    //     };
    // }, []);

    return (
        <>
            <Navbar />
            <Banner />
            <Location />
            <Message />
            <LatestNews/>
            <Stats/>
            {/* <DepartmentsSection /> */}
            <HealthPackages />
            <Booking />
            <Partners />

            {/* Workshop Modal */}
            {/* <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="neuroWorkshop" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title workshop_title" id="neuroWorkshop">Ashwini Group of Hospitals Conducts its 2nd Workshops Neuro-Radiology for Clinicians</h5>
                        </div>
                        <div className="modal-body">
                            <p>The event, held at Nishamani Convention Hall, Cuttack, featured India's top experts in Neuro Radiology, providing clinicians with advanced knowledge and insights. Thank you to all participants and speakers for their contributions.</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary close_btn" data-bs-dismiss="modal">Close</button>
                            <div className="btn-box text-center">
                                <a href={"/News&Media"} className="theme-btn btn-one">
                                    <span>Know More</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* The New Modal Section Start */}
            <div className={`modal fade ${showModal ? 'show' : ''}`} tabIndex="-1" style={{ display: showModal ? 'block' : 'none' }} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header ">
                            <h5 className="modal-title workshop_title text-danger " id="exampleModalLabel">Cuttack’s First Robotic Knee Replacement Surgery Now at
                            Ashwini Hospital!</h5>
                            <button type="button" className="btn-close" onClick={handleClose} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                        Experience cutting-edge robotic surgery with unmatched precision and quicker recovery.
                        </div>
                        <img src={Img} alt="" className="modal-image" />
                        {/* height={360} width={799} */}
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
                            <div className="btn-box text-center">
                                <a href={"/Blog/Robotic"} className="btn btn-primary">
                                    <span>Know More</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* The New Modal Section End */}
            {showModal && <Confetti numberOfPieces={200} gravity={0.2} />}
        </>
    )
}

export default Homepage;