import React, { useState } from "react";
import NavbarLocation from "../../Navbar/NavbarLocation";
import Img1 from "../../../assets/doctors/Soumya_Pradhan.png";
import G1 from "../../../assets/department photos/neuro1.png";
import G2 from "../../../assets/department photos/neuro2.png";
import G3 from "../../../assets/department photos/neuro3.png";
import G4 from "../../../assets/department photos/neuro4.png";
import G5 from "../../../assets/department photos/neuro5.png";
import G6 from "../../../assets/department photos/neuro6.png";
import G7 from "../../../assets/department photos/neuro7.png";
import G8 from "../../../assets/department photos/neuro8.png";
import G9 from "../../../assets/department photos/neuro9.png";
import G10 from "../../../assets/department photos/neuro10.jpg";
import G11 from "../../../assets/department photos/neuro11.jpg";
import G12 from "../../../assets/department photos/neuro12.jpg";
import G13 from "../../../assets/department photos/neuro13.jpg";
import G14 from "../../../assets/department photos/neuro14.jpg";
import G15 from "../../../assets/department photos/neuro15.jpg";
import G16 from "../../../assets/department photos/neuro16.jpg";
import G17 from "../../../assets/department photos/neuro17.jpg";
import G18 from "../../../assets/department photos/CT Scan.jpg";
import G19 from "../../../assets/department photos/neuro18.jpg";
import G20 from "../../../assets/department photos/neuro19.jpg";
import G21 from "../../../assets/department photos/neuro23.jpg";
import G22 from "../../../assets/department photos/neuro20.jpg";
import G23 from "../../../assets/department photos/neuro21.jpg";
import G24 from "../../../assets/department photos/neuro22.jpg";
import G25 from "../../../assets/department photos/criticalcare14.jpg";
import G26 from "../../../assets/department photos/MRI.jpg";
import G27 from "../../../assets/department photos/stroke-ward.jpg";
import G28 from "../../../assets/department photos/neuro-opd.jpg";
import G29 from "../../../assets/department photos/reception.jpg";
import G30 from "../../../assets/department photos/The Brainery.jpg";
import G31 from "../../../assets/department photos/neuro24.jpg";
import G32 from "../../../assets/department photos/seminar.jpg";
import G33 from "../../../assets/department photos/icmr.jpg";
import G34 from "../../../assets/department photos/neuro-club-meet.jpg";
import G35 from "../../../assets/department photos/neuro association meet.jpg";
import G36 from "../../../assets/department photos/Volume Analysis.jpg";
import G37 from "../../../assets/department photos/aneurysm coiling.jpg";
import G38 from "../../../assets/department photos/dsa prcedure.jpg";
import G39 from "../../../assets/department photos/neuro-immunology-workshop.jpg";
import G40 from "../../../assets/department photos/neuro25.jpg";
import G41 from "../../../assets/department photos/neuro-club-meet2.jpg";
import G42 from "../../../assets/department photos/neuro26.jpg";
import G43 from "../../../assets/department photos/neuro27.jpg";
import G44 from "../../../assets/department photos/neuro28.jpg";
import G45 from "../../../assets/department photos/eeg.jpg";
import G46 from "../../../assets/department photos/team neurology.jpg";
import G47 from "../../../assets/department photos/Dr.Maya Gantayet performing DSA.jpg";

function AAHNeurology() {
  const [openIndex, setOpenIndex] = useState(null);
  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index); // Toggle accordion open/close
  };
  const slides = [
    {
      image: Img1,
      name: "Dr. Soumya Ranjan Pradhan",
      qualification: "DM(Neurology), DrNB Faculty (Neurology)",
      location: "Ashwini Hospital, Aditya Ashwini Hospital",
    },
  ];

  const gallery = [
    { image: G47, caption: "Dr. Maya Gantayet Performing DSA Procedure" },
    { image: G46, caption: "Dr. Maya Gantayet with Neuro Intervention Team" },
    { image: G44, caption: "Renowned Neurologist Dr. Nasli R. Ichaporia Honored by Dr. Maya Gantayet at Neuro-Radiology Workshop, Ashwini Group Of Hospitals" },
    { image: G43, caption: "Renowned Neurologist Dr. Nasli R. Ichaporia Attends Neuro-Radiology Workshop at Ashwini Group Of Hospitals" },
    { image: G42, caption: "ANEICON: Dr. Maya Gantayet at the Odisha Neurology Association Meeting" },
    { image: G41, caption: "Dr. Maya Gantayet at Neuroclubs Meet" },
    { image: G39, caption: "Neuro-Immunology Workshop" },
    { image: G40, caption: "Neuro-Radiology Workshop" },
    { image: G1, caption: "Case Evaluation" },
    { image: G2, caption: "ICU Area" },
    { image: G3, caption: "Clinical Round Of Dr. Maya Gantayet at Stroke Ward" },
    { image: G4, caption: "Clinical Round Of Dr. Maya Gantayet at Stroke Ward" },
    { image: G5, caption: "Patient at ICU" },
    { image: G6, caption: "Stroke Prevention Awareness Programme" },
    { image: G7, caption: "" },
    { image: G8, caption: "" },
    { image: G9, caption: "" },
    { image: G10, caption: "Dr. Maya Gantayet Discussing About Patient Health Status" },
    { image: G11, caption: "" },
    { image: G12, caption: "Neuro ICU" },
    { image: G13, caption: "ICU" },
    { image: G14, caption: "Neuro ICU" },
    { image: G15, caption: "Clinical Round Of Dr. Maya Gantayet" },
    { image: G16, caption: "Dr. Maya Gantayet Examining the Patient" },
    { image: G17, caption: "Dr. Maya Gantayet Examining the Patient" },
    { image: G18, caption: "MRI Machine" },
    { image: G19, caption: "Dr.Soumya Ranjan Pradhan Performing DSA Procedure" },
    { image: G20, caption: "Dr.Soumya Ranjan Pradhan Performing DSA Procedure" },
    { image: G21, caption: "Walkathon on Stroke Day " },
    { image: G22, caption: "Walkathon on Stroke Day " },
    { image: G23, caption: "Walkathon on Stroke Day " },
    { image: G24, caption: "Walkathon on Stroke Day " },
  ];

  const opdTimings = [
    {
      doctor: "Dr. Soumya Ranjan Pradhan",
      schedule: [
        {
          day: "MON",
          time: "09:30 AM - 05:00 PM",
          centre: "Aditya Ashwini Hospital",
        },
        {
          day: "WED",
          time: "09:30 AM - 05:00 PM",
          centre: "Aditya Ashwini Hospital",
        },
        {
          day: "FRI",
          time: "09:30 AM - 05:00 PM",
          centre: "Aditya Ashwini Hospital",
        },
        {
          day: "SAT",
          time: "09:30 AM - 05:00 PM",
          centre: "Aditya Ashwini Hospital",
        },
      ],
    },
  ];

  const [currentImage, setCurrentImage] = useState("");
  const [currentCaption, setCurrentCaption] = useState("");
  const openModal = (image, caption) => {
    setCurrentImage(image);
    setCurrentCaption(caption);
    const modalElement = document.getElementById("exampleModal");
    const modal = new window.bootstrap.Modal(modalElement);
    modal.show();
  };

  return (
    <>
      <NavbarLocation />

      {/* Neurology Doctors */}
      <div className="doctors-box">
        <div className="container">
          <div className="sec-title">
            <h1>Our Esteemed Consultants</h1>
          </div>

          <div className="row row-cols-1 row-cols-md-4 g-4 justify-content-center">
            {slides.map((slide, index) => (
              <div className="col-lg-3 col-md-4" key={index}>
                <a
                  className="doc-name"
                  href={`/Doctors/${slide.name
                    .replace(/\s+/g, "")
                    .toLowerCase()}`}
                >
                  <div className="card h-100 doctor_img">
                    <img
                      src={slide.image}
                      alt={`${slide.name} Img`}
                      title={slide.name}
                    />
                    <div className="doc-text">
                      <h3>{slide.name}</h3>
                      <p>{slide.qualification}</p>
                      <span className="text-dark">{slide.location}</span>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Section */}
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-12 col-sm-12 mb-3">
            {/* opd timings */}
            <div className="card mb-3">
              <div className="card-body">
                <h2 className="card-title widget-title mb-3">OPD Timings</h2>
                <div className="widget-content">
                  {opdTimings.map((opd, index) => (
                    <div className="accordion-item" key={`opd-${index}`}>
                      <h2 className="accordion-header" id={`heading${index}`}>
                        <button
                          className={`accordion-button ${
                            openIndex === index ? "" : "collapsed"
                          }`}
                          type="button"
                          onClick={() => toggleAccordion(index)}
                          aria-expanded={openIndex === index ? "true" : "false"}
                          aria-controls={`collapse${index}`}
                        >
                          {opd.doctor}
                          <span className="toggle-text">
                            {openIndex === index ? "Hide" : "Show"}
                          </span>
                        </button>
                      </h2>
                      <div
                        id={`collapse${index}`}
                        className={`accordion-collapse collapse ${
                          openIndex === index ? "show" : ""
                        }`}
                        aria-labelledby={`heading${index}`}
                        data-bs-parent="#opdTimingsAccordion"
                      >
                        <div className="accordion-body">
                          <table className="table table-hover table-light">
                            <thead>
                              <tr>
                                <th scope="col">DAYS</th>
                                <th scope="col">TIMINGS</th>
                                <th scope="col">CENTRE</th>
                              </tr>
                            </thead>
                            <tbody>
                              {opd.schedule.map((schedule, idx) => (
                                <tr
                                  className="opd-table"
                                  key={`schedule-${index}-${idx}`}
                                >
                                  <th scope="row">{schedule.day}</th>
                                  <td>{schedule.time}</td>
                                  <td>{schedule.centre}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* gallery */}
            <div className="card">
              <div className="card-body">
                <h2 className="card-title widget-title mb-3">Media</h2>
                <div className="widget-content clearfix">
                  <ul className="image-list">
                    {gallery.map((g, index) => (
                      <li key={index}>
                        <figure>
                          <img
                            src={g.image}
                            alt={g.caption}
                            title={g.caption}
                            onClick={() => openModal(g.image, g.caption)}
                          />
                        </figure>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            {/* Modal */}
            <div
              className="modal fade"
              id="exampleModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                      Image Preview
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <p>{currentCaption}</p>
                    <img
                      src={currentImage}
                      alt={currentCaption}
                      title={currentCaption}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-8 col-md-12 col-sm-12 mb-3">
            <div className="card">
              <div className="card about-style-two py-0">
                <div className="container card-body">
                  <div className="card-text">
                    <h3 className="sec-title">About Neurology Department</h3>
                    <p>
                      Ashwini Group Of Hospitals, founded in 2006, has grown to
                      become the leading <b>Neurocenter</b> in Odisha,
                      specializing in advanced Neurology services. Recognized as
                      a top provider of <b>Stroke Care</b>, our dedicated stroke
                      unit is equipped with high-end facilities, offering
                      expertise in <b>Thrombolysis, Mechanical Thrombectomy</b>,
                      and advanced <b>Neuroimaging</b>. Since its inception, the
                      Neurology Department has remained committed to delivering
                      exceptional patient care while fostering academic
                      excellence, making Ashwini Hospital the preferred choice
                      for patients with Neurological Disorders.
                    </p>
                    <h5 className="fs-6">What We Treat :-</h5>
                    <ul className="ul_list fix-list">
                      <li>Stroke and Interventional Proceduers</li>
                      <li>Neuroimmunological Disorders</li>
                      <li>Epilepsy</li>
                      <li>Movement Disorders</li>
                      <li>Alzheimer's and Parkinson's Disease</li>
                      <li>Neuromuscular Disorders</li>
                      <li>Headache, Migraine and related Diseases</li>
                    </ul>
                    <div className="container card-body">
                      <h3 className="sec-title">
                        Academic Excellence at Ashwini Group Of Hospitals
                      </h3>
                      <p>
                        Our department prioritizes academic development through
                        various activities aimed at enhancing knowledge and
                        expertise in Neurology.
                      </p>
                      <ul>
                        <li>
                          <strong className="font-weight">
                            Grand Rounds & Bedside Clinical Discussions:
                          </strong>{" "}
                          Regular case evaluations and neurological disease
                          localization.
                        </li>
                        <li>
                          <strong className="font-weight">
                            Weekly Seminars:
                          </strong>{" "}
                          Conducted twice a week, involving Emergency Medicine,
                          Neurosurgery, Radiology, and Pathology.
                        </li>
                      </ul>
                      <div className="row text-center">
                        {[
                          { src: G3, name: "Clinical Round at ICU" },
                          { src: G16, name: "Stroke ICU Round" },
                          { src: G25, name: "Neuro Ward Round" },
                          { src: G31, name: "Bedside Round" },
                          { src: G32, name: "Seminar Teaching" },
                          { src: G1, name: "Case Evaluations" },
                        ].map((facility, index) => (
                          <div key={index} className="col-md-4 mb-4">
                            <div className="card h-100 shadow-sm">
                              <img
                                src={facility.src}
                                alt={facility.name}
                                title={facility.name}
                                className="img-fluid"
                                style={{ height: "200px", objectFit: "fill" }}
                              />
                              <div
                                className="p-2"
                                style={{ backgroundColor: "aliceblue" }}
                              >
                                <h5 className="fs-6 text-primary">
                                  {facility.name}
                                </h5>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="container card-body">
                      <h3 className="sec-title">Patient Care and Services</h3>
                      <p>
                        Ashwini Group Of Hospitals handles over{" "}
                        <b>18,000+ Neurological Cases</b> annually, serving
                        patients from Odisha and neighboring states.
                      </p>
                      <ul>
                        <li>
                          <strong className="font-weight">Stroke Care:</strong>{" "}
                          Comprehensive stroke management, including
                          thrombolysis.
                        </li>
                        <li>
                          <strong className="font-weight">
                            Electrophysiology Services:
                          </strong>{" "}
                          EEG, Video EEG, VEP, BERA, NCS, and EMG for various
                          neurological conditions.
                        </li>
                        <li>
                          <strong className="font-weight">
                            Epilepsy Clinic:
                          </strong>{" "}
                          Specialized OPD with weekly consultations and
                          follow-ups.
                        </li>
                        <li>
                          <strong className="font-weight">
                            Advanced Neurology Procedures:
                          </strong>{" "}
                          Botulinum toxin injections, nerve, and muscle
                          biopsies.
                        </li>
                        <li>
                          <strong className="font-weight">
                            Dedicated Neurology ICU & Stroke Unit:
                          </strong>{" "}
                          Intensive care for critical neurology patients.
                        </li>
                      </ul>
                      <div className="row text-center mt-4">
                        {[
                          { src: G14, name: "Neuro ICU" },
                          { src: G27, name: "Stroke Ward" },
                          { src: G13, name: "ICU" },
                          { src: G28, name: "OPD" },
                          { src: G29, name: "Reception" },
                          { src: G30, name: "The Brainery" },
                        ].map((facility, index) => (
                          <div key={index} className="col-md-4 mb-4">
                            <div className="card h-100 shadow-sm">
                              <img
                                src={facility.src}
                                alt={facility.name}
                                title={facility.name}
                                className="img-fluid"
                                style={{ height: "200px", objectFit: "fill" }}
                              />
                              <div
                                className="p-2 "
                                style={{ backgroundColor: "aliceblue" }}
                              >
                                <h5 className="fs-6 text-primary">
                                  {facility.name}
                                </h5>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="container card-body">
                      <h4 className="sec-title">
                        State-of-the-Art Diagnostics & Imaging
                      </h4>
                      <ul>
                        <li>
                          <strong className="font-weight">
                            CT & MRI Imaging:
                          </strong>{" "}
                          High-end CT scan with perfusion-diffusion analysis and
                          a 1.5 Tesla MRI.
                        </li>
                        <li>
                          <strong className="font-weight">
                            Stroke Care Equipment:
                          </strong>{" "}
                          Includes CT perfusion, DSA, and functional MRI for
                          stroke management.
                        </li>
                      </ul>
                      <div className="row text-center mt-4">
                        {[
                          { src: G20, name: "DSA Lab" },
                          { src: G18, name: "MRI" },
                          { src: G26, name: "CT Scan" },
                          { src: G36, name: "Brain Volume Analysis" },
                          { src: G37, name: "Aneurysm Coiling" },
                          { src: G45, name: "Bedside EEG" },
                        ].map((facility, index) => (
                          <div key={index} className="col-md-4 mb-4">
                            <div className="card h-100 shadow-sm">
                              <img
                                src={facility.src}
                                alt={facility.name}
                                title={facility.name}
                                className="img-fluid"
                                style={{ height: "200px", objectFit: "fill" }}
                              />
                              <div
                                className="p-2 "
                                style={{ backgroundColor: "aliceblue" }}
                              >
                                <h5 className="fs-6 text-primary">
                                  {facility.name}
                                </h5>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="contaoiner card-body">
                      <h4 className="sec-title">
                        Leading Stroke Care in Odisha
                      </h4>
                      <p>
                        Ashwini Hospital is recognized as a leading provider of
                        stroke care in Odisha. Our stroke unit is equipped with
                        high-end facilities and our expertise in thrombolysis,
                        mechanical thrombectomy, and advanced neuroimaging makes
                        us a top choice for patients with neurological
                        disorders.
                      </p>
                      <div className="row text-center mt-4">
                        {[
                          { src: G15, name: "Stroke Care" },
                          { src: G38, name: "DSA Procedure" },
                          { src: G6, name: "Stroke Awareness" },
                        ].map((facility, index) => (
                          <div key={index} className="col-md-4 mb-4">
                            <div className="card h-100 shadow-sm">
                              <img
                                src={facility.src}
                                alt={facility.name}
                                title={facility.name}
                                className="img-fluid"
                                style={{ height: "200px", objectFit: "fill" }}
                              />
                              <div
                                className="p-2 "
                                style={{ backgroundColor: "aliceblue" }}
                              >
                                <h5 className="fs-6 text-primary">
                                  {facility.name}
                                </h5>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="contaoiner card-body">
                      <h4 className="sec-title">
                        Collaboration with Renowned Institutions
                      </h4>
                      <p>
                        Ashwini Hospital is a center for the Hospital-Based
                        Stroke Registry by ICMR-NCDIR, one of only four in the
                        state. Our team also engages in Twin City Neuroclubs and
                        Neurology Association of Odisha meetings.
                      </p>
                      <div className="row text-center">
                        {[
                          { src: G33, name: "ICMR-NCDIR Meet" },
                          { src: G34, name: "Neuroclub Meet" },
                          { src: G35, name: "Neurology Association Meet" },
                        ].map((facility, index) => (
                          <div key={index} className="col-md-4 mb-4">
                            <div className="card h-100 shadow-sm">
                              <img
                                src={facility.src}
                                alt={facility.name}
                                title={facility.name}
                                className="img-fluid"
                                style={{ height: "200px", objectFit: "fill" }}
                              />
                              <div
                                className="p-2"
                                style={{ backgroundColor: "aliceblue" }}
                              >
                                <h5 className="fs-6 text-primary">
                                  {facility.name}
                                </h5>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="container card-body">
                      <h3 className="sec-title">
                        Hospital Infrastructure & Specialties
                      </h3>
                      <div className=" mt-2">
                        <div className="row">
                          <p>
                            Ashwini Hospital boasts state-of-the-art
                            infrastructure and specialized facilities:{" "}
                          </p>
                          <ul className="custom-style">
                            <li>Dedicated Stroke ICU</li>
                            <li>
                              CT Perfusion & Digital Subtraction Angiography
                              (DSA)
                            </li>
                            <li>EEG (Electroencephalogram) & Video EEG</li>
                            <li>
                              High-End MRI with Brain Volume Analysis (for
                              conditions like dementia, epilepsy, and multiple
                              sclerosis)
                            </li>
                            <li>
                              NCS (Nerve Conduction Study), EMG, & Transcranial
                              Doppler
                            </li>
                            <li>24/7 CT Scan</li>
                            <li>Over 500+ Beds, Including 100+ ICU Beds</li>
                            <li>10+ Operation Theaters</li>
                            <li>18,000+ Neurological Cases Per Year</li>
                            <li>24/7 Stroke Team</li>
                            <li>
                              Mechanical Thrombectomy & Thrombolysis for Stroke
                              Care
                            </li>
                            <li>
                              Collaborations with Neurosurgery for
                              Neuronavigation Procedures
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="container card-body">
                      <h3 className="sec-title">Specialized Clinics</h3>
                      <div className=" mt-2">
                        <div className="row">
                          <div className="col-md-4">
                            <ul className="text-nowrap">
                              <li>Neuroimmunology Clinic</li>
                              <li>Stroke Clinic</li>
                              <li>Epilepsy Clinic</li>
                              <li>Movement Disorder Clinic</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Facilities Available */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AAHNeurology;
