import React from "react";
import NavbarLocation from "../../Navbar/NavbarLocation";
import Img2 from "../../../assets/doctors/user.png";

function ATCAnesthesiology() {
    const slides = [
        {
            image: Img2,
            name: "Dr. Nimisha Padhi",
            qualification: "MD(Anesthesia)",
            location: "Ashwini Trauma Centre"
        }
    ]

    return (
        <>
            <NavbarLocation />

            {/* Anesthesiology Doctors */}
            <div className="doctors-box">
                <div className="container">
                    <div className="sec-title">
                        <h1>Our Esteemed Consultants</h1>
                    </div>

                    <div className="row row-cols-1 row-cols-md-4 g-4 justify-content-center">
                    {slides.map((slide, index) => (
                            <div className="col-lg-3 col-md-4" key={index}>
                                <a className="doc-name" href={`/Doctors/${slide.name.replace(/\s+/g, '').toLowerCase()}`}>
                                    <div className="card h-100 doctor_img">
                                        <img src={slide.image} alt={`${slide.name} Img`} title={slide.name} />
                                        <div className="doc-text">
                                            <h3>{slide.name}</h3>
                                            <p>{slide.qualification}</p>
                                            <span className="text-dark">{slide.location}</span>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Section */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12 mb-3">
                        {/* gallery photos */}
                        <div className="card">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">Media</h2>
                                <div className="widget-content clearfix">
                                    <ul className="image-list">
                                        <p>To be added</p>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-12 col-sm-12 mb-3">
                        <div className="card">
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="card-text">
                                        <p>At Ashwini Hospitals, our Anesthesiology Department is at the forefront of patient safety and comfort, leveraging advanced facilities and expertise to deliver optimal anesthesia care. Our modern anesthesia suites are equipped with state-of-the-art monitoring systems, including capnography, pulse oximetry, and neuromuscular monitoring, enabling our experienced team of anesthesiologists and certified registered nurse anesthetists (CRNAs) to closely monitor patients throughout their surgical procedures. We offer a wide range of anesthesia techniques tailored to each patient's individual needs, including general anesthesia, regional anesthesia, and intravenous sedation. Additionally, our facility features dedicated pre-operative assessment clinics where patients undergo thorough evaluations to identify and mitigate potential anesthesia-related risks. We prioritize patient safety through comprehensive pre-operative education, ensuring that patients are well-informed about anesthesia procedures, fasting guidelines, and post-operative recovery expectations. Our commitment to excellence extends beyond the operating room, as we provide ongoing pain management services and support patients through every stage of their perioperative journey.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ATCAnesthesiology;