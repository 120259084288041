import React, { useState } from "react";
import NavbarLocation from "../../Navbar/NavbarLocation";
import Img1 from "../../../assets/doctors/sitarasmi_rath.png";
import G1 from "../../../assets/department photos/cardiology3.jpg";
import G2 from "../../../assets/department photos/cardiology4.jpg";
import G3 from "../../../assets/department photos/cardiology5.jpg";

function AAHCardiology() {
    const [openIndex, setOpenIndex] = useState(null);
    const [currentImage, setCurrentImage] = useState('');
    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index); // Toggle accordion open/close
    };

    const openModal = (image) => {
        setCurrentImage(image);
        const modalElement = document.getElementById('exampleModal');
        const modal = new window.bootstrap.Modal(modalElement);
        modal.show();
    };
    const slides = [
        {
            image: Img1,
            name: "Dr. Sitarasmi Rath",
            qualification: "MD(Medicine), DM(Cardiology)",
            location: "Aditya Ashwini Hospital"
        }
    ];

    const gallery = [
        { image: G1 },
        { image: G2 },
        { image: G3 },
    ];

    const opdTimings = [
        {
            doctor: 'Dr. Sitarasmi Rath',
            schedule: [
                { day: 'MON', time: '09:30 AM - 05:00 PM', centre: 'Aditya Ashwini Hospital' },
                { day: 'TUE', time: '09:30 AM - 05:00 PM', centre: 'Aditya Ashwini Hospital' },
                { day: 'WED', time: '09:30 AM - 05:00 PM', centre: 'Aditya Ashwini Hospital' },
                { day: 'THU', time: '09:30 AM - 05:00 PM', centre: 'Aditya Ashwini Hospital' },
                { day: 'FRI', time: '09:30 AM - 05:00 PM', centre: 'Aditya Ashwini Hospital' },
                { day: 'SAT', time: '09:30 AM - 05:00 PM', centre: 'Aditya Ashwini Hospital' }
            ]
        }
    ]
    return (
        <>
            <NavbarLocation />

            {/* Cardiology Doctors */}
            <div className="doctors-box">
                <div className="container">
                    <div className="sec-title">
                        <h1>Our Esteemed Consultants</h1>
                    </div>

                    <div className="row row-cols-1 row-cols-md-4 g-4 justify-content-center">
                    {slides.map((slide, index) => (
                            <div className="col-lg-3 col-md-4" key={index}>
                                <a className="doc-name" href={`/Doctors/${slide.name.replace(/\s+/g, '').toLowerCase()}`}>
                                    <div className="card h-100 doctor_img">
                                        <img src={slide.image} alt={`${slide.name} Img`} title={slide.name} />
                                        <div className="doc-text">
                                            <h3>{slide.name}</h3>
                                            <p>{slide.qualification}</p>
                                            <span className="text-dark">{slide.location}</span>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Section */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12 mb-3">
                        {/* opd timings */}
                        <div className="card mb-3">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">OPD Timings</h2>
                                <div className="widget-content">
                                {opdTimings.map((opd, index) => (
                                        <div className="accordion-item" key={`opd-${index}`}>
                                            <h2 className="accordion-header" id={`heading${index}`}>
                                                <button
                                                    className={`accordion-button ${openIndex === index ? "" : "collapsed"}`}
                                                    type="button"
                                                    onClick={() => toggleAccordion(index)}
                                                    aria-expanded={openIndex === index ? "true" : "false"}
                                                    aria-controls={`collapse${index}`}
                                                >
                                                    {opd.doctor}
                                                    <span className="toggle-text">
                                                        {openIndex === index ? "Hide" : "Show"}
                                                    </span>
                                                </button>
                                            </h2>
                                            <div
                                                id={`collapse${index}`}
                                                className={`accordion-collapse collapse ${openIndex === index ? "show" : ""}`}
                                                aria-labelledby={`heading${index}`}
                                                data-bs-parent="#opdTimingsAccordion"
                                            >
                                                <div className="accordion-body">
                                                    <table className="table table-hover table-light">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">DAYS</th>
                                                                <th scope="col">TIMINGS</th>
                                                                <th scope="col">CENTRE</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {opd.schedule.map((schedule, idx) => (
                                                                <tr className="opd-table" key={`schedule-${index}-${idx}`}>
                                                                    <th scope="row">{schedule.day}</th>
                                                                    <td>{schedule.time}</td>
                                                                    <td>{schedule.centre}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        {/* gallery */}
                        <div className="card">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">Media</h2>
                                <div className="widget-content clearfix">
                                    <ul className="image-list">
                                        {gallery.map((g, index) => (
                                            <li key={index}>
                                                <figure><img src={g.image} alt="Cardiology Gallery" title="Cardiology Department" onClick={() => openModal(g.image)} /></figure>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* Modal */}
                        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h1 className="modal-title fs-5" id="exampleModalLabel">Image Preview</h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <img src={currentImage} alt="Cardiology Gallery" title="Cardiology Department" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-12 col-sm-12 mb-3">
                        <div className="card">
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="card-text">
                                        <p>Ashwini Hospital is a premier Cardiac institute of Odisha since 2006. A steady increase of diagnostic and therapeutic procedures including some breakthrough lifesaving techniques have positioned Ashwini Cardiac Centre as a top performer in the State of Odisha. A strong team of senior as well as younger consultants have made the uninterrupted day and night care possible across all seasons. Be it a routine cardiac check-up before a major surgery or a difficult post-operative cardiac monitoring, the Cardiologists of Ashwini Hospital have proved their mettle beyond doubt. Wellness clinics, camps and preventive cardiac health check-ups have helped numerous patients identify their ailments before they could take the patient by surprise.</p>

                                        <p>Our strength is in our dedicated and skilful Cardiologists and Surgeons trained with latest technologies. They are supported by well trained technicians and cardiac nurses and a robust critical care back up in ICCU. We literally, have solution for any Cardiac emergencies like Heart attack (Acute Coronary Syndromes), Heart failure (CHF), Arrythmias, Congenital heart diseases, Coronary vessel diseases and Valvular dysfunctions. We have cutting edge advanced GE Innova Cath-lab, considered the work horse of diagnostic and therapeutic cardiac and cerebral interventions. IABP for a failing heart, Coronary intervention in acute or chronic scenario, peripheral vascular intervention in diabetic arterio-sclerosis and so on, the Cardiology team is ready to meet every cardio-vascular requirement besides routine intervention and diagnostic catheterisation.</p>
                                    </div>
                                </div>
                            </div>

                            {/* In Cardiology, we offer diagnostic as well as therapeutic services */}
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="sec-title">
                                        <h1>In Cardiology, we offer diagnostic as well as therapeutic services</h1>
                                    </div>
                                    <div className="card-text">
                                        <ul className="ul_list">
                                            <li>Angiogram (Radial / femoral)</li>
                                            <li>Pacemaker</li>
                                            <li>Peripheral Angiogram / Angioplasty</li>
                                            <li>Daily OPD and 24 hour emergency services</li>
                                            <li>Preventive Cardiology health check up</li>
                                            <li>ICT, CRT</li>
                                            <li>Angioplasty</li>
                                            <li>Valvuloplasty (mitral / pulmonary)</li>
                                            <li>4 Vessel DSA</li>
                                            <li>ECG, 2D Echo, TMT, Holter Monitoring</li>
                                            <li>Devise closure ASD/VSD/PDA</li>
                                            <li>24 hours HOLTER</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AAHCardiology;